import { Button, Modal, Select } from 'antd';
import React, { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch, useHistory, useLocation } from 'react-router-dom';
import { ErrorBoundary } from './ErrorBoundary';
import Loader from './components/utility/loader';
import { PUBLIC_ROUTE } from './route.constants';
import { toast } from 'react-toastify';
import api from './services/api';

// const { checkAuthorization } = authAction;

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('./containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('./containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import('./containers/Pages/SignUp/SignUp')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() => import('./containers/Pages/ForgotPassword/ForgotPassword')),
  },
  {
    path: `${PUBLIC_ROUTE.RESET_PASSWORD}/:id`,
    component: lazy(() => import('./containers/Pages/ResetPassword/ResetPassword')),
  },
  {
    path: `${PUBLIC_ROUTE.PROFISSIONAL_LINK_CONFIRMATION}/:token`,
    component: lazy(() => import('./containers/Pages/linkApprove/Professional/LinkProfessional')),
  },
  {
    path: `${PUBLIC_ROUTE.CUSTOMER_LINK_CONFIRMATION}/:token`,
    component: lazy(() => import('./containers/Pages/linkApprove/Customer/LinkCustomer')),
  },
  // {
  //   path: PUBLIC_ROUTE.TEST_NEW_CASHFLOW_TABLE,
  //   component: lazy(() => import('./containers/Financial/CashFlow/CashFlowList')),// app/src/zbullshit/CashFlow/CashFlowList.js
  // },
  {
    path: PUBLIC_ROUTE.TEST_NEW_CASHFLOW_TABLE,
    component: lazy(() => import('./zbullshit/CashFlow/CashFlowList')),// app/src/zbullshit/CashFlow/CashFlowList.js
  },
];

const PrivateRoute = ({ children, ...rest }) => {
  const user = useSelector((state) => state.Auth.user);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [availableCustomers, setAvailableCustomers] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const isLoading = useSelector((state) => state.Auth.isLoading);
  const isLoggedIn = useSelector((state) => state.Auth.loggedIn);

  const handleSelectedCustomer = useCallback(async () => {
    if (user?.permissions[0] === 'PROFESSIONAL') {
      const { data } = await api.get('/construction');
      if (!data.length) {
        localStorage.removeItem('selectedCustomer');
        const customId = 'custom-id-yes';
        toast.warn('Cadastre/atribua um cliente primeiro.', {
          toastId: customId,
        });
        return history.push('/dashboard/customer/new');
      }

      const payload = data.map((item) => ({
        constructionId: item.id,
        ...item.proprietary,
      }));

      if (payload.length === 1) {
        return localStorage.setItem('selectedCustomer', JSON.stringify(payload[0]));
      }

      setAvailableCustomers(payload);

      const selectedCustomer = localStorage.getItem('selectedCustomer');
      const parsedCustomer = JSON.parse(selectedCustomer);
      if (!parsedCustomer?.id) {
        setModalOpen(true);
      }
    }
  }, [history, user]);

  useEffect(() => {
    handleSelectedCustomer();
  }, [location.pathname]);

  const handleOk = () => {
    setConfirmLoading(true);
    localStorage.setItem('selectedCustomer', JSON.stringify(customer));
    setTimeout(() => {
      setModalOpen(false);
      setConfirmLoading(false);
    }, 1000);
  };

  const handleSelect = (id) => {
    const data = availableCustomers.find((item) => item.id === id);
    setCustomer(data);
  };

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          modalOpen ? (
            <Modal
              open={modalOpen}
              title="Seleção de Cliente"
              centered
              footer={[
                <Button key="submit" type="primary" disabled={!customer} loading={confirmLoading} onClick={handleOk}>
                  Confirmar
                </Button>,
              ]}
              destroyOnClose
            >
              <Select
                placeholder="Selecione seu cliente"
                style={{ width: '100%' }}
                className="antd-select"
                size="large"
                fieldNames={{ label: 'name', value: 'id' }}
                options={availableCustomers}
                onSelect={handleSelect}
              />
            </Modal>
          ) : (
            children
          )
        ) : isLoading ? (
          <Loader />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export const Routes = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}

            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>

            <Redirect to="/signin" />
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
};
